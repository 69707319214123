<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :md="5" :sm="24">
            <a-form-item
              label="年份"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="年份"
                v-model="queryParams.year"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in messageYears"
                  :key="t"
                  :value="t"
                  >{{ t }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button
          v-hasPermission="['message:add']"
          type="primary"
          ghost
          @click="add"
          >新增</a-button
        >
        <a-button v-hasPermission="['message:delete']" @click="batchDelete"
          >删除</a-button
        >
      </div>
      <!-- 表格区域 -->
      <a-table
        bordered
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :scroll="{ x: 900 }"
        @change="handleTableChange"
      >

        <template slot="userlist" slot-scope="list, record">
          <template v-for="(data, index) of list">
            <a-tag
              style="margin: 0 5px 5px"
              :key="index"
              v-if="record.showAll || index < 6"
            >
              {{ data.username }}
            </a-tag>
          </template>

          <span
            style="white-space: nowrap; color: #3db6fc; cursor: pointer;"
            v-if="list.length > 6"
            @click="record.showAll = !record.showAll"
          >
            {{ record.showAll ? "折叠▲" : "展开▼" }}
          </span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon
            v-hasPermission="['message:update']"
            type="edit"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            title="修改"
            @click="edit(record)"
          ></a-icon>
          &nbsp;
          <a-icon
            v-hasPermission="['message:view']"
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            title="查看"
            @click="view(record)"
          ></a-icon>
          <a-badge
            v-hasNoPermission="['message:update', 'message:view']"
            status="warning"
            text="无权限"
          ></a-badge>
        </template>
      </a-table>
    </div>
    <fenxiang-edit ref="messageEditView" @success="onSuccess" />
    <fenxiang-detail ref="messageDetailView" />
  </a-card>
</template>

<script>
import moment from "moment";
import FenxiangEdit from "./FenxiangEdit";
import FenxiangDetail from "./FenxiangDetail";

export default {
  name: "Fenxiang",
  components: { FenxiangEdit, FenxiangDetail },
  data() {
    return {
      advanced: false,
      selectedRowKeys: [],
      queryParams: {
        type: 3,
        year: moment().year()
      },
      filteredInfo: null,
      paginationInfo: null,
      dataSource: [],
      messageYears: [],
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 15,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      loading: false,
    };
  },
  mounted() {
    this.$get("message/year").then((r) => {
      this.messageYears = r.data.data;
      this.search();
    });
  },
  methods: {
    search() {
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;

      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }

      this.$get("message/list", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.pagination = pagination;
        this.loading = false;
        if (data.rows == undefined) {
          this.dataSource = []
          return
        }

        let datasource = data.rows;
        let set = new Set();
        for (let item of datasource) {
          for (const id of item.userIdValueList) {
            set.add(id);
          }
          set.add(item.createUserId)
        }
        let ids = [...set].join(",");

        this.$get(`user/list/simple/${ids}`).then((resp) => {
          let arr = resp.data.data;

          for (let item of datasource) {
            item.userlist = [];
            item.showAll = false;
            for (const id of item.userIdValueList) {
              let one = arr.find((i) => i.userId == id);
              if (one) {
                item.userlist.push(one);
              }
            }
            item.creator = arr.find((i) => i.userId == item.createUserId);
          }

          this.dataSource = datasource;
        });
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleTableChange(pagination, filters, sorter) {
      // 将这三个参数赋值给Vue data，用于后续使用
      this.paginationInfo = pagination;
      this.filteredInfo = filters;

      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.queryParams,
        ...filters,
      });
    },
    add() {
      this.$refs.messageEditView.setForm(false);
    },
    edit(record) {
      this.$refs.messageEditView.setForm(true, record);
    },
    view(record) {
      this.$refs.messageDetailView.setData(record);
    },
    batchDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
        return;
      }
      let that = this;
      this.$confirm({
        title: "确定删除所选中的记录?",
        content: "当您点击确定按钮后，这些记录将会被彻底删除",
        centered: true,
        onOk() {
          let ids = that.selectedRowKeys.join(",");
          that.$delete("message/" + ids).then(() => {
            that.$message.success("删除成功");
            that.selectedRowKeys = [];
            that.fetch({ ...that.queryParams });
          });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    onSuccess() {
      this.fetch({ ...this.queryParams });
    },
  },
  computed: {
    columns() {
      return [
        {
          title: "接收者",
          dataIndex: "userlist",
          scopedSlots: { customRender: "userlist" },
        },
        {
          title: "分享标题",
          dataIndex: "title",
          width: 250,
        },
        {
          title: "附件数",
          dataIndex: "attachment",
          width: 100,
          customRender: (v) => {
            return JSON.parse(v || '[]').length + '个附件';
          },
        },
        {
          title: "发送时间",
          dataIndex: "sendTime",
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 160,
        },
        {
          title: "发出标记",
          dataIndex: "marked",
          customRender: (v) => {
            return v ? "已发出" : "等待发出...";
          },
          width: 160,
        },
        {
          title: "创建者",
          dataIndex: "creator",
          customRender: (data) => {
            return data.username;
          },
          width: 160,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 160,
        },
        {
          title: "操作",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" },
          width: 70,
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>